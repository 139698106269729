<template>
    <div>
        <Header>
        </Header>
        <div class="not-found">
            <div class="wrapper-content">
                <div class="not-found__content">
                    <h1 class="not-found__title">
                        Такой страницы<br/> не найдено
                    </h1>
                    <div class="not-found__calculator">
                        <Calculator
                            v-model="calculator"
                            class="not-found__calculator-component"
                        />
                        <router-link :to="{ name: 'ContactInfo' }" class="not-found__button">
                            Подобрать займ
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.11959e-07 9L12.17 9L6.58 14.59L8 16L16 8L8 -6.99382e-07L6.59 1.41L12.17 7L7.86805e-07 7L6.11959e-07 9Z"
                                />
                            </svg>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import './not-found.scss';
import Header from '@index/components/common/header/Header';
import Calculator from '@index/components/common/calculator/Calculator';

export default {
    name: 'NotFound',
    data() {
        return {
            calculator: 10000
        }
    },
    components: {
        Header,
        Footer: () => import('@index/components/common/footer/Footer'),
        Calculator
    }
}
</script>